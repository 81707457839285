import {
  Alert,
  Box,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import MiniDrawer from "../navigation/Drawers";
import Mtables from "../controller/Mtables";
import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  Error,
  Search,
  UpdateOutlined,
  Verified,
  Visibility,
} from "@mui/icons-material";
import Boutons from "../controller/Boutons";
import TextFields from "../controller/TextFields";
import { request } from "../url/request";
import Dialogs from "../controller/Dialogs";
import NotifyError from "../Notification/NotifyError";
import { useNavigate } from "react-router-dom";
import InputEvenement from "./InputEvenement";
import NotifySuccess from "../Notification/NotifySuccess";
import ErrorConnection from "../../outher/ErrorConnection";
var Spink = require("react-spinkit");

const column = [
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Evenement
      </Typography>
    ),
    selector: (row) => row.evenement,
    sortable: false,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Risque associé
      </Typography>
    ),
    selector: (row) => row.typevlnerable,
    sortable: true,
  },
  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Type d'évènement
      </Typography>
    ),
    selector: (row) => row.tyevenemt,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Validiter
      </Typography>
    ),
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => {
      return (
        <Stack direction={"row"} spacing={1} sx={{ p: 1 }}>
          {row.status === true ? (
            <Alert icon={<Verified />} severity="success">
              Verifier
            </Alert>
          ) : (
            <Alert icon={<Error />} severity="warning">
              Non verifier
            </Alert>
          )}
        </Stack>
      );
    },
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Date
      </Typography>
    ),
    selector: (row) => row.date,
    sortable: true,
  },

  {
    name: (
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Action
      </Typography>
    ),
    selector: (row) => row.action,
    sortable: true,
  },
];

function Evenement() {
  document.title = "Evenement | EFFICACITY SYSTEM";
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [opendialigue, setOpenDialogue] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [dataUpdate, setDataUpdate] = React.useState("");
  const nav = useNavigate();
  const [etat, setEtat] = React.useState(false);
  const [etatv, setEtatV] = React.useState(false);
  const [etatcheckconn, setEtatChecConn] = React.useState(false);

  const Recherche = (e) => {
    setSearchQuery(e.target.value);
    // console.log(e);
  };

  const getEvenement = () => {
    setEtat(false);
    setEtatChecConn(false);
    request
      .get("evenement")
      .then((e) => {
        let itm = e.data.data.map((e) => {
          return {
            evenement: e?.titreev,
            description: e?.descryptionev,
            typevlnerable: e?.ttypevulnerabilite?.designationv,
            tyevenemt: e?.ttypeevenement?.designationtypeev,
            emplacement: e?.templacement?.designationsite,
            partieprenamte: e?.tpartiprenante?.designaionp,
            agent:
              e?.tagent?.tpersonne?.nom + "" + e?.tagent?.tpersonne?.postnom,
            date: e?.date,
            // dateupdate: e.dateupdate,
            status: e?.etat_evenement,
            action: (
              <Stack direction={"row"} spacing={0}>
                <IconButton
                  size="medium"
                  color="primary"
                  onClick={() => upDate(e)}
                >
                  <Edit fontSize="medium" />
                </IconButton>

                <IconButton
                  size="medium"
                  color="success"
                  onClick={() =>
                    nav("/evenement-detaille", {
                      state: {
                        itemv: e,
                      },
                    })
                  }
                >
                  <Visibility fontSize="medium" />
                </IconButton>

                <IconButton
                  size="medium"
                  color="warning"
                  onClick={() => ConfirmInformation(e)}
                >
                  <UpdateOutlined fontSize="medium" />
                </IconButton>
              </Stack>
            ),
          };
        });
        setData(itm);
        setEtat(true);
        setEtatChecConn(false);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };
  const dataFilter = data.filter((itemx) =>
    searchQuery.toUpperCase() === null ||
    searchQuery.toUpperCase() === "" ||
    searchQuery.toUpperCase() === undefined
      ? itemx
      : (itemx &&
          itemx.evenement &&
          itemx.evenement.toUpperCase().includes(searchQuery.toUpperCase())) ||
        itemx.typevlnerable.toUpperCase().includes(searchQuery.toUpperCase())
  );
  React.useEffect(() => {
    getEvenement();
  }, []);

  const nouveauevenement = () => {
    setDataUpdate("");
    setOpenDialogue(!opendialigue);
    setTitle("Enregistrement");
  };

  const upDate = (e) => {
    setDataUpdate(e);
    setOpenDialogue(!opendialigue);
    setTitle("Modification");
  };
  const ConfirmInformation = (e) => {
    setEtatV(!etatv);
    setDataUpdate(e);
  };
  const ValidationDataUpdateEtat = () => {
    request
      .put("evenement/validate", {
        idevenement: dataUpdate.idevenement,
      })
      .then((e) => {
        NotifySuccess(e.data.message);
        getEvenement();
        setEtatV(!etatv);
      })
      .catch((error) => {
        if (error.request) {
          NotifyError(error.request);
          setEtat(false);
          setEtatChecConn(true);
        } else if (error.response) {
          NotifyError(error.response.data.message);
          setEtat(false);
          setEtatChecConn(false);
        } else if (error.config) {
          NotifyError(error.config);
          setEtat(false);
          setEtatChecConn(false);
        } else {
          NotifyError(error.message);
          setEtat(false);
          setEtatChecConn(false);
        }
      });
  };
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#E3EAEF",
        // minHeight: "100vh",
        maxWidth: "100%",
        flexWrap: "wrap",
        overflowX: "hidden",
      }}
    >
      <MiniDrawer />
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: 5,
          pl: 1,
          // mr: 3,
        }}
      >
        <div
          style={{
            background: "white",
            borderRadius: 5,
            padding: 10,
            marginTop: 40,
          }}
        >
          <Typography variant="h5" fontWeight={"Bolder"}>
            Evenements
          </Typography>
          <Typography variant="h5" fontSize={14}>
            Ici vous trouverez les évènements enregistrés
          </Typography>
        </div>
        {etatcheckconn === true ? (
          <ErrorConnection />
        ) : etat === false ? (
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <Spink name="wave" color="#409EFF" />
            <Typography>Patienter</Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              // bgcolor: "red",
              p: 3,
              mt: 2,
              // ml: -1,
            }}
          >
            <div
              style={{
                position: "absolute",
                flexWrap: "wrap",
                width: "80%",
                marginTop: "-3%",
                marginLeft: -30,
                marginBottom: 90,
                background: "#E3EAEF",
                padding: 10,
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  padding: 10,
                }}
              >
                <Boutons
                  onClick={() => nouveauevenement()}
                  icons={
                    <Add
                      sx={{
                        mr: 2,
                      }}
                    />
                  }
                  title="Nouvel évènement"
                />
              </div>
              {etatv === false ? (
                ""
              ) : (
                <Stack
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E3EAEF",
                    marginTop: 20,
                    borderRadius: 5,
                    margin: 10,
                  }}
                >
                  <Typography fontWeight={"bold"} fontSize={20}>
                    Information
                  </Typography>
                  <Divider />
                  <Typography fontWeight={"bold"} fontSize={16}>
                    Etes-vous sur de valider ?
                  </Typography>
                  <Stack direction={"row"} spacing={2} sx={{ m: 2 }}>
                    <IconButton
                      sx={{
                        bgcolor: "#013266",
                        ":hover": {
                          bgcolor: "#1976D2",
                        },
                      }}
                      onClick={() => ValidationDataUpdateEtat()}
                    >
                      <Check
                        sx={{
                          color: "#FFF",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                    <IconButton
                      sx={{
                        bgcolor: "red",
                        ":hover": {
                          bgcolor: "#1976D2",
                        },
                      }}
                      onClick={() => setEtatV(!etatv)}
                    >
                      <Close
                        sx={{
                          color: "#FFF",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  </Stack>
                </Stack>
              )}
              <Stack direction={"row"} justifyContent={"space-between"} mt={-5}>
                <h1></h1>

                <div
                  style={{
                    marginRight: 1,
                    marginBottom: 10,
                  }}
                >
                  <TextFields
                    value={searchQuery}
                    onChange={(e) => {
                      Recherche(e);
                    }}
                    icons={
                      <Search
                        fontSize="large"
                        sx={{ mr: 1, my: 0.5, color: "#192C56" }}
                      />
                    }
                    placeholder="Rechercher"
                  />
                </div>
              </Stack>
              <Mtables
                title="Liste des évènements"
                data={dataFilter}
                columns={column}
              />
            </div>
          </Stack>
        )}
      </Box>
      <Dialogs
        title={title}
        open={opendialigue}
        setOpen={setOpenDialogue}
        size="md"
      >
        <InputEvenement dataUpdate={dataUpdate} fonct={getEvenement} />
      </Dialogs>
    </Box>
  );
}

export default Evenement;
